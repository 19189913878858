@charset "UTF-8";
/* Title: BuyPower Admin Stylesheet
** Author: BuyPower Team - https://github.com/BuyPower
** Created: May 8, 2018
************************************** */

/* = Colors
 * orange: #f7931e
 * green: #8ca23b
 * red-ish: #f6624A
*/
.black-text {
  color: black !important;
}

.cursor-pointer,
.page-item,
tr {
  cursor: pointer;
}

.timeline-list {
  list-style: none;
}

.navbar-dark {
  background-color: #555555 !important;
}

.blue-text {
  color: #2d3cc4;
}

.hover-blue:hover {
  cursor: pointer;
  color: blue;
  font-size: 1.3em;
  transition-duration: 0.2s;
}

.collapsed-col .col-3 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* CSS Spinner */
.spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.user-card {
  border: 1px solid #8ca23b;
  padding: 15px 15px;
  border-radius: 10px;
  display: inline-block;
  margin: 20px;
}

.user-card span {
  font-size: 18px;
}

.user-card img {
  width: 60px;
  margin-right: 7px;
}

.user-card a {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.user-card:hover {
  color: white;
  background-color: #8ca23b;
}

.settlement-dash {
  background: #f99932;
  color: #ffffff;
  padding: 10px 20px 0 20px;
}
.modal-title {
  margin-top: 0;
}

.debt-row {
  border-bottom: 1px solid #efefef;
  padding: 0 15px 20px;
  line-height: 5px;
}

.debt-row h4 {
  color: #6f6f6f;
  font-size: 16px;
  line-height: 0px;
}

.debt-row h6 {
  font-size: 20px;
  line-height: 0px;
  margin: 0px;
}

.debt-row h5 {
  font-size: 12px;
  color: #afafaf;
  margin: 1px 0;
  line-height: 0px;
}

.modal-body .map {
  height: 500px;
}

.vend-request-response-card {
  padding: 5px;
}
